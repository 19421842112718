import React from "react";
import Logo from "assets/icons/pami-white-logo.svg";
import styled from "styled-components";
import { ColorRing } from "react-loader-spinner";

export default function LogoLoader({ both }) {
  return (
    <AnimationWrapper both={both}>
      <img className="icon-2" src={Logo} />
      {both && (
        <ColorRing
          visible={true}
          height="120"
          width="120"
          ariaLabel="blocks-loading"
          colors={["#987555", "#987555", "#987555", "#987555", "#987555"]}
        />
      )}
    </AnimationWrapper>
  );
}

const AnimationWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100vh;
  position: relative;

  #ldio-4offds5dlws-mask {
    circle {
      stroke-width: 3px !important;
    }
  }
  .icon-1 {
    circle[fill="#46dff0"] {
      fill: #987555 !important;
    }
    circle[fill="rgba(233, 12, 89, 0.5125806451612902)"] {
      fill: purple !important;
    }
  }
  .icon-2 {
    position: absolute;
    width: 40px;
    height: 40px;
    animation: ${(props) => (props.both ? "none" : "blink 1.2s infinite")};
  }
  @keyframes blink {
    0% {
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
`;
