import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import LogoLoader from "components/common/logo-loader/LogoLoader";

const App = React.lazy(() => import("./App"));

ReactDOM.render(
  <React.StrictMode>
    <React.Suspense fallback={<LogoLoader />}>
      <App />
    </React.Suspense>
  </React.StrictMode>,
  document.getElementById("root")
);
reportWebVitals();
